.MessageTextInput {
  position: relative;
  margin: var(--margin-2);
  display: flex;
}

.MessageTextInput .messageWrapper {
  width: 100%;
}

.MessageTextInput textarea {
  background-color: var(--white);
  height: 100%;
  overflow-y: auto;
  resize: none;
  pointer-events: all;
}
.MessageTextInput textarea {
  border: 0;
  line-height: 1;
  padding: var(--margin-1);
  width: 100%;
}

.MessageTextInput .send {
  /* position: absolute; */
  background-color: var(--main);
  border-radius: 50%;
  /* right: 0;
  top: var(--margin-05); */
}
.MessageTextInput .drag-over textarea {
  background-color: var(--main);
}

.MessageTextInput .messageAction {
  display: flex;
  gap: 1px;
}

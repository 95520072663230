.MessengerChat{
	position: relative;
	height:100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content:stretch;
	flex-wrap: nowrap;
}

.MessengerChat .title{position: relative;line-height:3;padding-left:var(--margin-2);flex:0 0 auto}
.MessengerChat .title>.Icon{position: absolute;right:var(--margin-1);top:var(--margin-1);width: var(--margin-4);height: var(--margin-4);left:auto;}
.MessengerChat .title>.Icon .bgmask{background-color:var(--black-middle);}
.MessengerChat .title>.Icon.videocall{right:var(--margin-5);}
.MessengerChat .titleLine
{
	white-space: nowrap;
	max-width: 220px;
	overflow: auto;
}



/*CallState
*/
.MessengerChat .CallState
{
	position: absolute;
	top:0;
	right:var(--margin-4);
}
.MessengerChat .CallState .Icon
{
	position: relative;
	display: inline-block;
	left:unset;
	top:unset;

	width: var(--margin-5);
	height: var(--margin-5);
}

.MessengerChat .list{margin:var(--margin-2);flex:1 auto;overflow-y: auto;}

.MessengerChat .list .msgRow{position: relative;display:flex;width:calc(100% - var(--margin-2));background-color: var(--gray-middle-semi);margin-bottom: var(--margin-1);padding:var(--margin-1);border-radius: var(--margin-1);}
.MessengerChat .list .msgRow.right{flex-direction: row-reverse;margin-left: var(--margin-2);}
.MessengerChat .list .msgRow .UserImage{flex:0 0 auto}
.MessengerChat .list .msgRow .content{flex:1 1 auto;padding-left:var(--margin-1)}
.MessengerChat .list .msgRow .content .name{font-size:60%;color:var(--gray-dark);margin-bottom:var(--margin-1)}
.MessengerChat .list>.listEndAnchor{height:0;padding:0;margin:0;}

.MessengerChat .list .msgRow.new{border: 1px solid var(--main)}
.MessengerChat .list .msgRow.right.new{border: 1px solid var(--gray-dark)}

.MessengerChat .list .date{font-size:60%;text-align: center;}

.MessengerChat .list .tmp_id{position: absolute;font-size:50%;top:0;right:0;color:var(--gray-dark)}



.mobile .MessengerChat  .title > .arRight{
	transform: rotate(180deg);
	position: absolute;
	left:var(--margin-02);
}

.mobile .MessengerChat .titleLine
{
	margin-left:var(--margin-2);
}

.mobile .MessengerChat .title>.Icon.videocall{right:var(--margin-2);}
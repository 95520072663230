.root{
  position: relative;
  display: flex;
}

.btn{

}
.elements{
  background-color:var(--bg-2);
  border-radius: var(--border-radius);
  padding:var(--margin-1);
  border: 1px solid var(--bg-5);
  position: absolute;
  z-index: 2;
  display: inline-flex;
  gap:var(--margin-1);
  max-width: 50vw;
  flex-wrap: wrap;



}

.label{
  text-transform: uppercase;
  font-size: 80%;
  margin-left: -2px;;
}

.element, .element>span{
  color:var(--fg-0);
  text-transform: capitalize;
  margin-right: 10px;
  cursor:pointer;
  position: relative;
}


.elements .element{
  --bg:'';
  border:1px solid var(--bg-3);
  border-radius: var(--border-radius);
  overflow: hidden;
  flex:0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: unset !important;

}

.element>.bg{
  filter: grayscale(1);
  background-image: var(--bg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1.6;
  height:var(--margin-2_5);
  opacity: .3;

}

.element:hover>.bg, .element.active>.bg{
  opacity: .7;
  filter: grayscale(0.2);
  transition: opacity .5s;
}

.elements .element>span{
  position: absolute;
  margin-right: unset !important;
  text-shadow: 0px 0px 2px #000; ;
  text-transform: uppercase;
  margin-top:5px;
}

.element.dark{
  color:var(--bg-0);
}

.element.active{
  border-color:var(--fg-0)
}

.elements>*{

}
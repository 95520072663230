.root{
	position:relative;
	color:var(--gray-dark);
	padding: var(--margin-1) 0;
	padding-right:var(--margin-1);
	background-color: var(--white);
	display:flex;
	flex-direction: column;
	align-items: stretch;
}
.root:hover{background-color: var(--gray-light);transition: background-color 0.5s;}

.root .light{
	display:flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;
	gap:var(--margin-2);
}

.root .UserImage{
	--size:var(--margin-5);
	margin-left:var(--margin-2);

}

.UserName{
	flex:1 1 auto;
}

.ActionIcon{
	flex:0 0 auto;
}
/*
.root .profile .badge{
	position: absolute;
	border-radius: 50%;
	width:var(--margin-2);
	height:var(--margin-2);
	background-color:var(--main);
	color:var(--white);
	text-align: center;
	font-size: var(--fsize_3);
	line-height:calc(2.5 * var(--margin-1));
	right:-3px;top:-2px;
}
*/
.root>span{padding-top:7px;}

.root.active{color:var(--main)}

.userInfoPopup{

}

.actions{
	display: flex;
	gap:var(--margin-2);
	flex:1 1 auto;
	justify-content: flex-end;

}

.connectionAccept, .connectionDeny{
	--color: var(--color-success);
	color:var(--color);
	border-radius: 50%;
	border:1px solid var(--color);
	aspect-ratio: 1;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.connectionAccept:hover, .connectionDeny:hover{
	background-color: var(--white);
	transform: scale(1.1);
	transition: all .5s;
}

.connectionAccept{
	--color: var(--color-success);
}

.connectionDeny{
	--color: var(--color-warning);
}
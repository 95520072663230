.root{
	position:absolute;

	position: relative;

	color:var(--gray-dark);
	font-size: 90%;


}

.content{
	min-width: 60%;
	position: relative;
	z-index: 999;
	top:0;

	word-wrap: break-word;
	padding:0;
	line-height: 1.3;
	white-space: pre-line;

	border-bottom: 1px solid var(--gray-light);
}


:global(body.portrait) .content{

}


.meta{
	padding:0 var(--margin-1);
	min-width: 150px;
}

.actions{

}

.actions>*{
	border-top: 1px solid var(--fg-3);
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--margin-1);
	display: flex;
	justify-content: space-between;
	gap:var(--margin-1)
}

.actions>*:hover{
	background-color: var(--fg-2);
}

.socialState{
	margin-top:var(--margin-1)
}
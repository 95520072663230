.root{
  pointer-events: all;
  height:0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.root.opened{
  height:100%

}

:global(body.portrait) .root.opened{
  overflow-y: auto;

}

p.title{
  color:var(--bg-dark);
  font-size: var(--fsize_8);
  padding-left:var(--margin-1);
}

.header{
  position: relative;
  color:var(--fg-1);
  display:flex;
  flex-direction: row;
  gap:var(--margin-1);
  align-items: center;
  padding:var(--margin-05);
  background-color: var(--bg-3);
}

.icon{
  border-radius: 50%;
  width:var(--margin-4);
  height:var(--margin-4);
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fg-3);
  color: var(--bg-2);
  font-size: var(--margin-3);
}

.closeBtn{
  position: absolute;
  right:var(--margin-1);
}


.body{
  background: var(--white);
  flex:1 1 auto;
  padding: var(--margin-1);

}

:global(body.portrait) .body{
  overflow-y: auto;
}

.left{
  position: absolute;
  inset:0;
  right:100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.left.opened{
  right:0vw;
}

.left .header{
  background-color: var(--bg-light);
  color:var(--bg-darker)
}

.left .backBtn{
  transform: rotate(180deg);
}

.actions{
  position: absolute;
  right:var(--margin-1);

}

.scrollable{
  overflow-y: auto;
}
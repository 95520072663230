.root {}

.root :global(.content){
  width: calc(100% - var(--margin-5));
}

.msgImg {
  position: absolute;
  max-height: 80px;
  max-width: 80px;
  z-index: 1;
}

.root a {
  font-size: 80%;
  line-height: 1;
  display: block;

}

a.image{

  max-height: 80px;
  height: auto;
}

a.image img{
  position: relative;
}


a.file{
  max-width:100%;
}
.root a span.urlLink {
  color: var(--blue);
  font-size: 80%;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-left:var(--margin-1);
  overflow: hidden;
  display:block;
}
.root {
  word-break: break-word;
  white-space: pre-line;
}

/*.MessengerChat .list .msgRow .content .msg a{font-size: unset;font-weight: unset;line-height:unset;color:unset;letter-spacing: unset;}
.MessengerChat .list .msgRow .content .msg a:hover{color:var(--black)}*/
.root{
  padding:var(--margin-1);
  max-height: 100%;
  overflow-y: auto;
}

.title{

}

.steps{
  width:100%;
  background-color: var(--fg-2);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
}

.step{
  flex: 1 1 auto;
  background-color: var(--fg-2);
  text-align: center;
  font-size: var(--fsize_4);
  padding:var(--margin-05);
  padding-top:var(--margin-1);
  opacity: 0.5;
  white-space: nowrap;
  transition: all 1;
}

:global(.App.mobile){
  font-size: var(--fsize_5);
}

.step.active{
  opacity: 1;
  flex: 2 2 auto;
  background-color: var(--main);
  color:var(--white);
}
.App {
  height: 100%;
  position: fixed;
  width: 100%;
  background-size: cover;
}

.App > .appContent {
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 998;
  width: 100%;
}

.mobileStack{
  display:flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
}
.mobileStack>.mobileBody{
  flex:1 1 auto;
  position: relative;
  transform: translateX(0);
  transition: transform 0.3s;
}

:global(body.menuOpened) .mobileStack>.mobileBody{

  transform: translateX(85vw);
  transition: transform  0.6s 0.2s;
}

.mobileStack>.mobileHeader, .mobileStack>.mobileFooter{
  flex:0 0 auto;

}
.mobileStack>.mobileFooter{
  z-index: 101;
}


.SelectCompanies {
  position: absolute;
  top: var(--margin-1);
  left: var(--margin-10);
}

.SelectWorkspace {
  position: absolute;
  top: var(--margin-12);
  right: var(--margin-12);
}


.logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  --size: 15vmin;
  width: var(--size);
  height: auto;
}


.logo img {
  width: var(--size);
  height: auto;
}

.logo {
  z-index: 4;
}

.Header {
  z-index: 3;
}

.views {
  z-index: 2;
}

.Messenger {
  z-index: 20;
}

._depr_footer {
  position: absolute;
  bottom: 0;
  color: var(--white);
  text-align: center;
  width: 100%;
  font-size: var(--fsize_3);
  letter-spacing: 1px;
  font-weight: 100;
}


.mobileLandscapeWarning {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main);
  background: var(--bg-dark-semi);
  backdrop-filter: blur(5px);
}
.mobileLandscapeWarning>p{
  max-width: "500px";
  text-align: "center";
}




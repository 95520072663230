.UserRow{
	position:relative;

	color:var(--gray-dark);
	padding: var(--margin-1) 0;
	background-color: var(--white);
	display:flex;
	align-items: center;
}
.UserRow:hover{background-color: var(--gray-light);transition: background-color 0.5s;}

.UserRow .UserImage{position: relative;margin-left:var(--margin-2);width:var(--margin-5);height:var(--margin-5);display:inline-block;flex:0 0 auto}

.UserRow .profile .badge{
	position: absolute;
	border-radius: 50%;
	width:var(--margin-2);
	height:var(--margin-2);
	background-color:var(--main);
	color:var(--white);
	text-align: center;
	font-size: var(--fsize_3);
	line-height:calc(2.2 * var(--margin-1));
	right:-3px;top:-2px;
}

.UserRow span{margin-left:var(--margin-2);padding-top:7px;}

.UserRow.active{color:var(--main)}

.UserImage{position: relative;width:var(--margin-5);height:var(--margin-5);display:inline-block}
.UserImage .Icon{background-color: var(--gray-middle);border-radius: 50%;padding:0;overflow:hidden;width: 100%;height: 100%;;}

@import "./assets/fonts/fonts.css";

.loading {
  color: var(--gray-3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  text-align: center;
  font-size: 32px;
}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: scroll !important;
}

body {
  --gray-0: rgb(22, 20, 21);
  --gray-1: rgb(30, 30, 30);
  --gray-2: rgb(55, 55, 55);
  --gray-3: rgb(112, 110, 111);
  --gray-4: rgb(202, 202, 202);

  --dark-bg-0: var(--gray-0);
  --dark-bg-1: var(--gray-1);
  --dark-bg-2: var(--gray-2);
  --dark-bg-3: var(--gray-3);
  --dark-bg-4: var(--gray-4);

  --dark-fg-0: #fff;
  --dark-fg-1: #f3f3f3;
  --dark-fg-2: #ebebeb;
  --dark-fg-3: #dadada;
  --dark-fg-4: #b1b1b1;

  --bg-0: var(--dark-bg-0);
  --bg-1: var(--dark-bg-1);
  --bg-2: var(--dark-bg-2);
  --bg-3: var(--dark-bg-3);
  --bg-4: var(--dark-bg-4);
  --bg-5: var(--dark-fg-4);
  --bg-6: var(--dark-fg-3);
  --bg-7: var(--dark-fg-2);
  --bg-8: var(--dark-fg-1);
  --bg-9: var(--dark-fg-0);

  --fg-0: var(--dark-fg-0);
  --fg-1: var(--dark-fg-1);
  --fg-2: var(--dark-fg-2);
  --fg-3: var(--dark-fg-3);
  --fg-4: var(--dark-fg-4);
  --fg-5: var(--dark-bg-4);
  --fg-6: var(--dark-bg-3);
  --fg-7: var(--dark-bg-2);
  --fg-8: var(--dark-bg-1);
  --fg-9: var(--dark-bg-0);

  --size-header-h: 4rem;
  --size-footer-h: 5rem;

  --color-success: #009e42;
  --color-danger: #d12221; /*#8c0014;*/
  --color-warning: #f5a032;
  --color-info: #a7a7a7;

  --red: #8c0014;
  --orange: #f59b00;
  --main: #f59b00;
  --main-semi: rgba(245, 155, 0, 0.5);
  --main-darker: #aa6c00;
  --gray-light: #ededed;
  --gray-middle: #d8d8d8;
  --gray-middle-semi: #d8d8d8cc;
  --gray-dark: #868686;
  --gray-dark-semi: #868686cc;
  --black-middle: #333;
  --black: #000;
  --black-semi: rgba(0, 0, 0, 0.4);
  --white: #fff;
  --white-semi: rgba(255, 255, 255, 0.2);
  --bg-dark: rgba(51, 51, 51, 0.9);
  --bg-dark-semi: rgba(0, 0, 0, 0.7);
  --bg-overlay: rgba(0, 0, 0, 0.5);
  --bg-admin: rgba(37, 42, 42, 0.8);
  --bg-instructions-modal: rgba(37, 42, 42, 0.95);
  --bg-light: #efefee;
  --bg-white: #ffffff;
  --bg_0: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  --bg_1: linear-gradient(180deg, rgb(37, 37, 37) 0%, rgba(0, 0, 0, 1) 100%);
  --bg_2: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  --bg_3: radial-gradient(
    farthest-corner at 50% 20%,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 0.6) 100%
  );
  --bg_tmp: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0.6) 0%,
    rgba(255, 0, 0, 0) 100%
  );
  --table-header: rgba(75, 80, 80, 0.8);
  --table-row: rgba(90, 93, 95, 0.8);
  --state-green: #16da14;
  --state-red: #ff0000;
  --state-gray: #ccc;
  --state-requested: #ccc;
  --state-new_request: #ff0000;
  --blue: #0074cc;
  --green: rgba(67, 124, 118, 0.8);
  --grey: #444d56;
  --grey-light: #656565;
  --margin: 2vmin;
  --margin-02: 2px;
  --margin-05: 4px;
  --margin-1: 8px;
  --margin-1_5: 12px;
  --margin-2: 16px;
  --margin-2_5: 20px;
  --margin-3: 24px;
  --margin-4: 32px;
  --margin-5: 40px;
  --margin-6: 48px;
  --margin-7: 56px;
  --margin-8: 64px;
  --margin-9: 72px;
  --margin-10: 80px;
  --margin-11: 88px;
  --margin-12: 96px;
  --margin-13: 104px;
  --margin-14: 112px;
  --margin-16: 128px;
  --margin-18: 144px;
  --margin-24: 192px;
  --margin-25: 200px;
  --margin-32: 256px;
  --margin-36: 288px;
  --margin-40: 320px;
  --margin-45: 350px;
  --margin-50: 400px;
  --margin-56: 448px;
  --margin-64: 512px;
  --fsize_0: calc(10px - 0.3vmin);
  --fsize_1: calc(10px - 0.1vmin);
  --fsize_2: calc(10px + 0.2vmin);
  --fsize_3: calc(10px + 0.3vmin);
  --fsize_4: calc(10px + 0.5vmin);
  --fsize_5: calc(10px + 0.7vmin);
  --fsize_6: calc(10px + 0.8vmin);
  --fsize_7: calc(10px + 1vmin);
  --fsize_8: calc(10px + 1.5vmin);
  --fsize_9: calc(10px + 2vmin);
  --fsize_10: calc(10px + 2.5vmin);
  --fsize_11: calc(10px + 3vmin);
  --fsize_12: calc(10px + 3.5vmin);
  --fsize_13: calc(10px + 4vmin);
  --fsize_14: calc(10px + 5vmin);
  --fsize_15: calc(10px + 6vmin);
  --border-radius: 10px;
  --input-border-radius: var(--margin-1);

  --theme-text: var(--fg-0);
  --theme-icon: var(--main);
  --theme-menu: "red";
  --theme-background: var(--bg-0);
  --theme-companySelect: var(--main);
  --theme-workspaceSelect: var(--main);
  --theme-menuText: var(--bg-0);
  --theme-btn-primary: var(--main);
  --theme-btn-secondary: var(--main);
  --theme-btn-danger: var(--main);
  --theme-btn-success: var(--main);
}

body.mobile {
  --fsize_0: calc(14px - 0.3vmin);
  --fsize_1: calc(14px - 0.2vmin);
  --fsize_2: calc(14px + 0.1vmin);
  --fsize_3: calc(14px + 0.2vmin);
  --fsize_4: calc(14px + 0.3vmin);
  --fsize_5: calc(14px + 0.5vmin);
  --fsize_6: calc(14px + 0.7vmin);
  --fsize_7: calc(14px + 1vmin);
  --fsize_8: calc(14px + 1.3vmin);
  --fsize_9: calc(14px + 1.5vmin);
  --fsize_10: calc(14px + 2vmin);
  --fsize_11: calc(14px + 2.5vmin);
  --fsize_12: calc(14px + 3vmin);
  --fsize_13: calc(14px + 4vmin);
  --fsize_14: calc(14px + 5vmin);
  --fsize_15: calc(14px + 6vmin);
}

.bg_01 {
  background: var(--bg_1);
}

body.portrait .App {
  --margin: 1vmin;
}

.bgimg {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bgmask {
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

.ia {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn_highlight {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: transparent;
}

.active .btn_highlight {
  animation-name: heighlight;
  animation-duration: 0.6s;
}

@keyframes heighlight {
  from {
    background-color: white;
  }
  to {
    background-color: transparent;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

input:focus {
  outline: none;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0 !important;
  }
  to {
    opacity: 1 !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

*:not(.exception) {
  animation: 0.5s ease-out 0s 1 fadeIn;
}

.exception,
.exception * {
  animation: 0s ease-out 0s 1 fadeIn;
}

/*#region scrollbar*/

.desktop *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  background-color: transparent;
}

.desktop *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
  right: 5px;
}

.desktop *::-webkit-scrollbar-thumb {
  background-color: var(--bronze-light);
  border-radius: 5px;
}

/*#endregion scrollbar*/

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  position: absolute;
  top: 0px;
  background-color: var(--bg-1);
}

.scrollColor {
  overflow-x: scroll;
  scrollbar-color: #d7d3d3 #f7f7f7;
  scrollbar-width: thin;
}
.scrollColor::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollColor::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px;
  margin: 10px 10px 10px 10px !important;
}

.scrollColor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d7d3d3 !important;
}

.scrollColor::-moz-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollColor::-moz-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px;
  margin: 10px 10px 10px 10px !important;
}

.scrollColor::-moz-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d7d3d3 !important;
}
.scrollColor::-o-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollColor::-o-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px;
  margin: 10px 10px 10px 10px !important;
}

.scrollColor::-o-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d7d3d3 !important;
}

#root {
  height: 100%;
  overflow: hidden;
  position: fixed;
}

.App a {
  text-decoration: none;
  color: var(--black);
}

.App h1 {
  font-size: var(--fsize_12);
  font-weight: 100;
  line-height: 1.3;
}

.App h2 {
  font-size: var(--fsize_4);
  font-weight: 200;
  letter-spacing: -0.32px;
}

.App p {
  font-weight: 100;
  line-height: 1.5;
  letter-spacing: 0.3px;
  white-space: pre-line;
}

.App a {
  font-size: var(--fsize_7);
  font-weight: 100;
  line-height: 1.5;
  letter-spacing: 0.3px;
}

.ui {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(assets/images/klarland/bg.jpg);
  background-size: 0;
}

._ui > *.Icon {
  display: inline-block;
  width: calc(var(--fsize_9) * 2);
  height: calc(var(--fsize_9) * 2);
}

.views {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--white);
  background-color: var(--bg-dark-semi);
  pointer-events: all !important;
}

.viewsNoBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--white);
  background-color: var(--bg-dark-semi);
  background-color: unset;
}

.mobile .views {
  /*padding:var(--margin-1);*/
  background-color: var(--gray-1);
}

.views:empty {
  display: none;
}

.views > * {
  position: absolute;
  top: 0;
  left: var(--margin-32);
  right: var(--margin-32);
  bottom: 0;
  color: var(--white);
  padding: var(--margin-12) 0;
}

.mobile .views > * {
  padding: 0;
  left: 0;
  right: 0;
}

.title {
  position: relative;
  overflow: visible;
}

.title .Icon {
  position: absolute;
  left: -70px;
  top: 10px;
  width: var(--margin-7);
  height: var(--margin-7);
}

.title h1 {
  display: inline;
}

.title ul {
  background-color: var(--white);
  color: var(--black);
  height: 0;
  overflow: hidden;
}

.title ul.opened {
  height: auto;
}

.title ul > li {
  line-height: 2;
  cursor: pointer;
  padding: var(--margin-1);
}

.title ul > li:hover {
  background-color: var(--gray-middle);
  transition: background-color 1s;
}

.title ul > li.active {
  color: var(--main);
}

.SelectCompanies {
  position: absolute;
  top: var(--margin-1);
  left: var(--margin-10);
}

.SelectWorkspace {
  position: absolute;
  top: var(--margin-12);
  right: var(--margin-12);
}

.Header {
  z-index: 3;
}

.views {
  z-index: 2;
}

.Messenger {
  z-index: 20;
}

.footer {
  position: absolute;
  bottom: 0;
  color: var(--white);
  text-align: center;
  width: 100%;
  font-size: var(--fsize_3);
  letter-spacing: 1px;
  font-weight: 100;
}

.App._3d .ui {
  background-image: none;
}

.App._3d .ia,
.App._3d a,
.App._3d li,
.App._3d .Icon {
  pointer-events: all;
}

.hidden-obj {
  display: none;
}

.todo {
  opacity: 0;
  font-size: 75%;
}

.env_local .todo,
.env_dev .todo {
  color: red;
  opacity: 1;
}

.env_dev .todo {
  color: red;
  opacity: 0.5;
}

.arrow_rotate_users {
  height: 30px !important;
  width: 30px !important;
}
.arrow_rotate_users_rotate {
  height: 30px !important;
  width: 30px !important;
  transform: rotate(-180deg);
}
.closeArrowInvite {
  font-size: 34px !important;
  color: white !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  margin-top: 2em !important;
  margin-right: 6em !important;
}

.centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

i.centered {
  aspect-ratio: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

em {
  font-style: italic;
}

.wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr;
}

.defaultContextmenuArea {
  grid-row: 1;
  grid-column: 1;
  background-color: aqua;
}

.customContextmenuArea1 {
  grid-row: 1;
  grid-column: 2;
  background-color: sandybrown;
}

.customContextmenuArea2 {
  grid-row: 2;
  grid-column: 1;
  background-color: tomato;
}

.customContextmenuArea3 {
  grid-row: 2;
  grid-column: 2;
  background-color: powderblue;
}

.contextMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: auto;
  width: fit-content;
}

.optionsList {
  padding: 12px;
  min-width: 107px;
  border-radius: 4px;
}

.optionListItem {
  padding: 4px;
  border-radius: 3px;
  list-style-type: none;
}

.optionListItem:hover {
  cursor: pointer;
}

.customContextmenuArea1ListWrapper {
  background-color: aqua;
}

.customContextmenuArea1ListItem {
  list-style-type: disclosure-closed;
}

.customContextmenuArea1ListItem:hover {
  background-color: sandybrown;
}

.customContextmenuArea2ListWrapper {
  background-color: #5e35b1;
}

.customContextmenuArea2ListItem {
  color: white;
}

.customContextmenuArea2ListItem:hover {
  background-color: tomato;
}

.customContextmenuArea3ListWrapper {
  background-color: #ffeb3b;
}

.customContextmenuArea3ListItem {
  list-style-type: disc;
}

.customContextmenuArea3ListItem:hover {
  background-color: powderblue;
}

.box-create {
  border: 2px solid var(--gray-dark);
  cursor: pointer;
  width: 200px;
  height: 200px;
}

.box-create-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.box-create-first-div {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.box-create-p {
  color: white;
  font-size: 14px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.rename-folder {
  display: flex;
  flex-direction: row;
  align-items: center;
}






.inapp{

}

.inapp .CardsViewscrolldown{
  display:none !important;
}

.offline .Header .right>*{
  display:none
}

.offline .Header .right>.language-selector, .offline .Header .right>.user, .offline .Header .right>.login{
  display:block
}

.offline .offline_hidden{
  display:none;
}


.offline_overlay{
  position: fixed;
  z-index: 99999;
  pointer-events: none;

  width:100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.offline_overlay>p{
  color:var(--white);
  font-size: 10rem;
  font-weight: bold;
  transform: rotate(90deg) translate(0,-100%);
  opacity: 0.08;
}
.danger {
  color: var(--color-danger);
}

.color_main {
  color: var(--main);
}

i.btn {
  background-color: var(--main);
  color: var(--fg-0);
  display: flex;

  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--fsize_4);
  width: var(--margin-4);
  height: var(--margin-4);
}

i.main {
  color: var(--main);
}
i.white {
  color: var(--fg-0);
}
i.danger {
  color: var(--color-danger);
}

i.main.ia:hover {
  color: var(--fg-1);
  transition: color 0.5s ease-in-out;
}

i.btn.danger {
  color: var(--fg-0);
  background-color: var(--color-danger);
}
i.btn.info {
  background-color: var(--bg-3);
}
i.btn.inverted {
  background-color: var(--fg-0);
  color: var(--main);
}

i.btn:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

i.icon-backBtn {
  transform: rotate(-90deg);
  font-size: var(--margin-3);
  color: var(--main);
  display: block;
}

i.up {
  display: inline-block;
  transform: rotate(0deg);
}
i.down {
  display: inline-block;
  transform: rotate(180deg);
}
i.left {
  display: inline-block;
  transform: rotate(-90deg);
}
i.right {
  display: inline-block;
  transform: rotate(90deg);
}

#devBadge {
  background-color: var(--main-semi);
  position: fixed;
  bottom: 60px;
  pointer-events: none;
  width: 100%;
  text-align: center;
  z-index: 999999;
  color: var(--white);
  font-size: var(--margin-1_5);
  padding: var(--margin-05);
  animation: hideAnimation 0.5s ease-in 10s forwards;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
@keyframes hideAnimation {
  to {
    bottom: -20px;
  }
}

#MobileVideoMinimizedPortal {
  position: absolute;
  bottom: var(--margin-1);
  left: var(--margin-1);
  z-index: 99;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@font-face {
  font-family: 'klarland';
  src: url('../font/klarland.eot?9818944');
  src: url('../font/klarland.eot?9818944#iefix') format('embedded-opentype'),
       url('../font/klarland.woff2?9818944') format('woff2'),
       url('../font/klarland.woff?9818944') format('woff'),
       url('../font/klarland.ttf?9818944') format('truetype'),
       url('../font/klarland.svg?9818944#klarland') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'klarland';
    src: url('../font/klarland.svg?9818944#klarland') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "klarland";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-settings:before { content: '\43'; } /* 'C' */
.icon-setting:before { content: '\44'; } /* 'D' */
.icon-doc:before { content: '\62'; } /* 'b' */
.icon-pin:before { content: '\66'; } /* 'f' */
.icon-pinned:before { content: '\67'; } /* 'g' */
.icon-send:before { content: '\69'; } /* 'i' */
.icon-views:before { content: '\6a'; } /* 'j' */
.icon-more:before { content: '\6b'; } /* 'k' */
.icon-login:before { content: '\6e'; } /* 'n' */
.icon-logout:before { content: '\6f'; } /* 'o' */
.icon-language:before { content: '\72'; } /* 'r' */
.icon-plus:before { content: '\77'; } /* 'w' */
.icon-minus:before { content: '\78'; } /* 'x' */
.icon-cancel:before { content: '\79'; } /* 'y' */
.icon-search:before { content: '\7a'; } /* 'z' */
.icon-klarland:before { content: '\e800'; } /* '' */
.icon-social:before { content: '\e801'; } /* '' */
.icon-like:before { content: '\e802'; } /* '' */
.icon-hand:before { content: '\e803'; } /* '' */
.icon-group:before { content: '\e804'; } /* '' */
.icon-user:before { content: '\e805'; } /* '' */
.icon-link:before { content: '\e806'; } /* '' */
.icon-share:before { content: '\e807'; } /* '' */
.icon-schedule:before { content: '\e808'; } /* '' */
.icon-user-remove:before { content: '\e809'; } /* '' */
.icon-time:before { content: '\e80a'; } /* '' */
.icon-trash:before { content: '\e80b'; } /* '' */
.icon-user-outline:before { content: '\e80c'; } /* '' */
.icon-write:before { content: '\e80d'; } /* '' */
.icon-pencil:before { content: '\e80e'; } /* '' */
.icon-setting-outline:before { content: '\e80f'; } /* '' */
.icon-attention:before { content: '\e810'; } /* '' */
.icon-hourglass:before { content: '\e811'; } /* '' */
.icon-connected:before { content: '\e812'; } /* '' */
.icon-eye:before { content: '\e813'; } /* '' */
.icon-eye-off:before { content: '\e814'; } /* '' */
.icon-ok:before { content: '\e815'; } /* '' */
.icon-comment:before { content: '\e816'; } /* '' */
.icon-chevron-mini:before { content: '\e817'; } /* '' */
.icon-social-unfollow:before { content: '\e818'; } /* '' */
.icon-social-follow:before { content: '\e819'; } /* '' */
.icon-social-unfollow_1:before { content: '\e81a'; } /* '' */
.icon-date:before { content: '\e81b'; } /* '' */
.icon-headset:before { content: '\e81c'; } /* '' */
.icon-menu:before { content: '\e81d'; } /* '' */
.icon-tile-folder:before { content: '\e81e'; } /* '' */
.icon-info:before { content: '\e81f'; } /* '' */
.icon-tile-forum:before { content: '\e820'; } /* '' */
.icon-broadcast-listen:before { content: '\e821'; } /* '' */
.icon-broadcast-minimize:before { content: '\e822'; } /* '' */
.icon-broadcast-maximize:before { content: '\e823'; } /* '' */
.icon-broadcast-share:before { content: '\e824'; } /* '' */
.icon-contact-request:before { content: '\e825'; } /* '' */
.icon-chain:before { content: '\e826'; } /* '' */
.icon-bell:before { content: '\e827'; } /* '' */
.icon-icon-add:before { content: '\e828'; } /* '' */
.icon-icon-addparticipant:before { content: '\e829'; } /* '' */
.icon-attachment:before { content: '\e82a'; } /* '' */
.icon-close:before { content: '\e82b'; } /* '' */
.icon-icon-comment:before { content: '\e82c'; } /* '' */
.icon-chat:before { content: '\e82d'; } /* '' */
.icon-icon-edit:before { content: '\e82e'; } /* '' */
.icon-globe:before { content: '\e82f'; } /* '' */
.icon-tile-html:before { content: '\e830'; } /* '' */
.icon-icon-info:before { content: '\e831'; } /* '' */
.icon-icon-likefill:before { content: '\e832'; } /* '' */
.icon-survey:before { content: '\e838'; } /* '' */
.icon-workspaceinfo:before { content: '\e839'; } /* '' */
.icon-tile-meeting:before { content: '\e83d'; } /* '' */
.icon-icon-sendmsg:before { content: '\e83e'; } /* '' */
.icon-icon-minimize:before { content: '\e83f'; } /* '' */
.icon-meeting:before { content: '\e840'; } /* '' */
.icon-newsfeed:before { content: '\e841'; } /* '' */
.icon-icon-maximize:before { content: '\e842'; } /* '' */
.icon-icon-search:before { content: '\e843'; } /* '' */
.icon-tiles:before { content: '\e844'; } /* '' */
.icon-calendar:before { content: '\e846'; } /* '' */
.icon-chevron:before { content: '\e847'; } /* '' */
.icon-tile-stage:before { content: '\e848'; } /* '' */
.icon-tile-workspace:before { content: '\e849'; } /* '' */
.icon-tile-company:before { content: '\e84a'; } /* '' */
.icon-tile-document:before { content: '\e84b'; } /* '' */
.icon-tile-external:before { content: '\e84c'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-disconnected:before { content: '\f127'; } /* '' */
.icon-bell-off:before { content: '\f1f7'; } /* '' */
.icon-question-outline:before { content: '\f29c'; } /* '' */

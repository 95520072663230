.root{
	position: relative;
	width:var(--margin-5);
	height:var(--margin-5);


}
.root :global(.Icon){
	background-color: var(--gray-middle);
	border-radius: 50%;
	padding:0;
	overflow:hidden;
	width: 100%;
	height: 100%;
}

.root img{
	position: absolute;left:0;border-radius: 50%;padding:0;overflow:hidden;width:100%;height:100%;object-fit: cover;}

.root .badge{
	position: absolute;
	border-radius: 50%;
	width:var(--margin-2);
	height:var(--margin-2);
	background-color:var(--main);
	color:var(--white);
	text-align: center;
	font-size: var(--fsize_3);
	line-height:calc(2.5 * var(--margin-1));
	right:-3px;top:-2px;
}

.fontIcon{
	font-size: var(--margin-3);
	border-radius: 50%;
	overflow:hidden;
	background-color: var(--gray-light);
	width: 100%;
	height: 100%;
	display:flex;
	justify-content: center;
	align-items: center;
}
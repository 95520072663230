
.surveyButton{
  position: fixed;
  width: var(--margin-5);
  height: var(--margin-5);
  background-color: var(--main);
  color:var(--fg-0);
  z-index: 999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--margin-3);
  pointer-events: all;
  bottom:var(--margin-2);
  right:var(--margin-2);
  box-shadow: 0px 1px 6px rgba(0,0,0,.3);

}

.surveyButton .badge{
  background-color: var(--fg-0);
  color:var(--main)
}
.surveyButton>i{
  animation-name: breath;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

@keyframes breath {
  0% {
    transform: scale(.8);
  }
  80% {
    transform: scale(.9);
  }
  100% {
    transform: scale(.8);
  }
}

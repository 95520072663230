.root{
  --size:var(--margin-4);
  position: relative;
  width:var(--size);
  height:var(--size);
  z-index: 1001;/*over messenger*/
}

.root.opened{

}

.root>div{
  --size:var(--margin-5);
  --id:0;
  position: absolute;
  top:calc(var(--id) * 0px);
  right:calc(var(--id) * 3px);
  right:0;
  transition: all .1s;
  z-index: calc(100 - var(--id));
  pointer-events: none;
  opacity: 0;
}
.root.opened>div{
  transition: all .4s;
  top:calc(var(--margin-5) + var(--id) * (var(--size) + var(--margin-1_5)));
  right:0;
  opacity: 1;
  pointer-events: all;
}

.root.opened>i{
  --size:var(--margin-4) !important;
  opacity: 1;
  color:var(--main);
  background-color: var(--white);
  top:unset;
}
.root>i{
  opacity: 1;
  font-size: var(--fsize_10);
  top:0;

  background-color:transparent;
}
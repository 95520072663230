.root{
	position:absolute;
	right:var(--margin-05)
}

.root :global(.accept){animation: phone-icon 500ms infinite;}

/*
.root :global(.Icon.plus){padding:var(--margin-1_5)}
.root :global(.Icon.plus>*){}
@keyframes phone-icon {
  0% { transform: translate3d(0px, 0, 0); }
	25% { transform: translate3d(1px, 1px, 0); }
	50% { transform: translate3d(0px, 0, 0) scale(1.0); }
	75% { transform: translate3d(-1px, 0, 0); }
  100% { transform: translate3d(0px, 0, 0); }
}*/
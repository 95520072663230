.MessengerChat{
	position: relative;
	height:100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content:stretch;
	flex-wrap: nowrap;
}

.MessengerChat .title{position: relative;line-height:3;padding-left:var(--margin-2);flex:0 0 auto}
.MessengerChat .title>.Icon{position: absolute;right:var(--margin-1);top:var(--margin-1);width: var(--margin-4);height: var(--margin-4);left:auto;}
.MessengerChat .title>.Icon .bgmask{background-color:var(--black-middle);}
.MessengerChat .titleLine
{
	white-space: nowrap;
	max-width: 220px;
	overflow: auto;
}


.mobile .MessengerChat {
  width: 100vw;
}


/*CallState
*/
.MessengerChat .CallState
{
	position: absolute;
	top:0;
	right:var(--margin-4);
}
.MessengerChat .CallState .Icon
{
position: relative;
display: inline-block;
left:unset;
top:unset;

width: var(--margin-5);
height: var(--margin-5);
}

.MessengerChat .list{margin:var(--margin-2);flex:1 auto;overflow-y: auto;pointer-events: all;}

.MessengerChat .list .msgRow{position: relative;display:flex;width:calc(100% - var(--margin-2));background-color: var(--gray-middle-semi);margin-bottom: var(--margin-1);padding:var(--margin-1);border-radius: var(--margin-1);}
.MessengerChat .list .msgRow.right{flex-direction: row-reverse;margin-left: var(--margin-2);}
.MessengerChat .list .msgRow .UserImage{flex:0 0 auto}
.MessengerChat .list .msgRow .content{flex:1 1 auto;padding-left:var(--margin-1)}
.MessengerChat .list .msgRow .content .name{font-size:60%;color:var(--gray-dark);margin-bottom:var(--margin-1)}
.MessengerChat .list .msgRow .content .msg{word-break: break-word;white-space:pre-line;}
.MessengerChat .list .msgRow .content .msg a{font-size: unset;font-weight: unset;line-height:unset;color:unset;letter-spacing: unset;}
.MessengerChat .list .msgRow .content .msg a:hover{color:var(--black)}
.MessengerChat .list>.listEndAnchor{height:0;padding:0;margin:0;height:10px; background-color: transparent;width:100%}

.MessengerChat .list .msgRow.new{border: 1px solid var(--main)}
.MessengerChat .list .msgRow.right.new{border: 1px solid var(--gray-dark)}

.MessengerChat .list .date{font-size:60%;text-align: center;}

.MessengerChat .list .tmp_id{position: absolute;font-size:50%;top:0;right:0;color:var(--gray-dark)}

.MessengerChat .list .msgRow .content .msg a.urlLink {
	user-select: text;
	color: var(--blue);
  }
  .MessengerChat .list .msgRow .content .msg a.urlLink:hover {
	color: var(--blue);
  }

.mobileHeader{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap:0;
  height:var(--size-header-h);
  background-color: var(--gray-2);
  color:var(--fg-0);
  font-weight: bolder;
  font-size: var(--fsize_5);
  z-index: 9;
}


body:global(.embeddedView) .mobileHeader{
  display: none;
}


:global(body.menuOpened) .mobileHeader{
  visibility: hidden;
}
.leftAction{
  flex:0 0 auto;
  width: var(--margin-6);
  display: flex;
  justify-content: center;
  align-items: center;
}


.center{
  flex:1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap:0;
  overflow: hidden;
  padding-right: var(--margin-6);
}

.logo{
  width: unset;
  position: relative;
  top:unset;
  left:unset;
  transform: none;
  flex: 1 1 auto;
  max-height: 50%;
  width: 100%;
  text-align: center;
  color:var(--orange)

}

.logo a{
  display: block;
  font-size: unset;
  font-weight: unset;
  line-height: 1;
  letter-spacing: 0;
  height: 100%;
}

.logo img{
  width:auto;
  height: 100%;
}

.logo i{/*klarland*/
  font-size: var(--margin-3);
}

.actions{
  display: flex;
  height: 100%;
  position: absolute;
  flex-direction: row;
  right:var(--margin-1);
  align-items: center;
  gap:var(--margin-1);
}

:global(#mobileHeaderLeftActionPortal>*), :global(#mobileHeaderTitlePortal>*), :global(#mobileHeaderActionsPortal>*){
  display: none;
}

:global(#mobileHeaderLeftActionPortal>*):last-child, :global(#mobileHeaderTitlePortal>*):last-child, :global(#mobileHeaderActionsPortal>*):last-child{
  display:block;
}

:global(#mobileHeaderTitlePortal>*>i:not(.btn)), :global(#mobileHeaderActionsPortal>*>i:not(.btn)){
  color:var(--main);
  font-size: var(--fsize_9);
}

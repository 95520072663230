.root{
  pointer-events: all;
}

.floatingCallStateContainer{
  position: fixed;
  z-index: 1;
  width:100%;
  height:auto;
  bottom: var(--margin-1);
  left:0;
  right:0;
  display:flex;
  justify-content: center;
  align-items: center;
}

.floatingCallState {

  background-color: rgba(255, 255, 255, .75);

  padding: var(--margin-1) var(--margin-2) var(--margin-05) var(--margin-3);
  z-index: 999999;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: var(--margin-1);
  border-radius: var(--margin-3);
  pointer-events: all;
}

.floatingCallState :global(.CallState) {
  right: unset;
  position: relative;
}

.rotated{
  transform: rotate(180deg);
}

.wrapper {
  height: 0px;
  width: 0px;
  top: "unset";
  left: "unset";
}

:global(body.portrait) .wrapperActive {
  inset:0;
}
.wrapperActive {
  position: fixed;

  /*inset:var(--size-header-h) 0 var(--size-footer-h) 0;*/
  inset:0;
  pointer-events: all;
  z-index: 1000;
  /*height: -webkit-fill-available;*/
}

.headerActionButtons{
  display: flex !important;
  align-items: center;
  color:var(--main);
}
.addGroupBtn{
  font-size: 2rem !important;
}
.root{
  position: relative;
}

.value{
  color:var(--fg-3);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:var(--margin-05);
  min-height: var(--margin-4);
}

.value i{
  display: inline-block;
  transform: rotate(180deg);
}

.root .selected{
  color:var(--main)
}

.root .content{
  display:none;
}

.root.opened .content{
  display:block;
  position: absolute;
  z-index: 10;
  background-color: var(--fg-1);
  border-radius: var(--margin-05);
  box-shadow: 0 0 0.5rem var(--bg-4);
  overflow: auto;
  max-height: 50vh;
  margin-top: var(--margin-1);
  top:calc(-1 * var(--margin-2));
  right: var(--margin-05);
  padding: var(--margin-1);
}

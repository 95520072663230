.badge {
  position: absolute;
  background-color: var(--main) !important;
  mask: none !important;
  top: 0;
  right: 0;
  width: auto !important;
  height: calc(var(--fsize_2) * 1.25) !important;
  line-height: 1.6;
  color: var(--white);
  font-size: var(--fsize_2);
  border-radius: var(--fsize_2);
  padding: 0 var(--margin-05);
}
.root{
	position:relative;

	color:var(--gray-dark);
	padding: var(--margin-1) 0;
	background-color: var(--white);
	display:flex;
	align-items: center;
	justify-content: stretch;
	gap:var(--margin-1);
	padding-left:var(--margin-2);
}
.root:hover{background-color: var(--gray-light);transition: background-color 0.5s;}

.root>*{
	flex:0 0 auto;
}

.root>.text{
	flex:1 1 auto;
}

/*.root .UserImage{position: relative;margin-left:var(--margin-2);width:var(--margin-5);height:var(--margin-5);display:inline-block;flex:0 0 auto}*/

.root.active{color:var(--main)}

.children{
	height:0;
	display:flex;
	align-items: center;
}
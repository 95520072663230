.root {
  position: relative;
  width: var(--size);
  height: var(--size);
  transition: width 0.2s, height 0.2s;
  display: inline-block;
  flex:0 0 auto;
}

.root :global(.Icon) {
  background-color: var(--gray-middle);
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  ;
}

.root img {
  position: absolute;
  left: 0;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.status {
  position: absolute;
  border-radius: 50%;
  width: calc(var(--size) * .3);
  height: calc(var(--size) * .3);
  background-color: var(--state-gray);
  right: -1px;
  bottom: 0px;
}

.status{
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--size) * .25);
  color:var(--white);
  padding-top: 5%;
}

.status:global(.available) {
  background-color: var(--state-green);
}

.status:global(.bisy) {
  background-color: var(--state-red);
}

.status:global(.requested) {
  background-color: var(--state-requested);
}

.status:global(.new_request) {
  background-color: var(--state-new_request);
}

.badge {
  position: absolute;
  border-radius: 50%;
  width: var(--margin-2);
  height: var(--margin-2);
  background-color: var(--main);
  color: var(--white);
  text-align: center !important;
  font-size: var(--margin-1_5);
  line-height: calc(2 * var(--margin-1));

  right: -3px;
  top: -2px;
}

.imgNotFound {
  opacity: 0;
  display: flex;
  position: absolute;
  top: var(--margin-05);
  word-wrap: break-word;
  align-items: center;
  justify-content: center;
  font-size: var( --fsize_6);
}
.root {
    color: aliceblue;
    padding:var(--margin-1);
}


.btns{
  display: flex;
  flex-wrap: wrap;
  gap:1rem;
  flex-direction: column;
}

.btns>div{
  display: flex;

  gap:1rem;
  flex-direction: row;
  align-items: center;
}

.btns>div>*{


  text-rendering: optimizeSpeed;
}

.root small{
  display: inline-block;
  color:var(--fg-3);
  padding-bottom: var(--margin-1);
  user-select: all;
}

.root h1{
  margin-top: var(--margin-2);
}
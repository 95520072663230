.root {
  position: absolute;
  bottom: calc(-1 * var(--margin-05));
  left: calc(-1 * var(--margin-1));
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  opacity: .5;
  ;
}

.root.active {
  opacity: 1;
}

.root>* {
  transform: scale(.7);
  transform-origin: bottom left
}
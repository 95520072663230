.root{
	position: absolute;
	border-radius: 50%;
	width: var(--margin-2);
	height: var(--margin-2);
	background-color: var(--main);
	color: var(--white);
	text-align: center;
	font-size: var(--margin-1_5);
	line-height: calc(2 * var(--margin-1));
	right: -3px;
	top: -2px;
	z-index: 1;
}
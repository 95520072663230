.root{

}

.name{
  position: absolute;
  top:var(--size);
  right:0;
  white-space: nowrap;
  font-weight: lighter;
  font-size:var(--fsize_0);
}
@import "./fontello-1243a634/css/klarland.css";
/*
@font-face {
	font-family: 'Meet';
	src:url("HelveticaNeueLTW1GTh.otf");
	font-weight: 100;
}

@font-face {
	font-family: 'Meet';
	src:url("HelveticaNeueLTW1GLt.otf");
	font-weight: 200;
}

@font-face {
	font-family: 'Meet';
	src:url("HelveticaNeueLTW1GRoman.otf");
	font-weight: 300;
}

@font-face {
	font-family: 'Meet';
	src:url("HelveticaNeueLTW1GRoman.otf");
	font-weight: 500;
}

@font-face {
	font-family: 'Meet';
	src:url("HelveticaNeueLTW1GBd.otf");
	font-weight: 700;
}
*/


@font-face {
	font-family: 'Meet';
	src:url("Roboto-Light.ttf");
	font-weight: 200;
}

@font-face {
	font-family: 'Meet';
	src:url("Roboto-Light.ttf");
	font-weight: 300;
}

@font-face {
	font-family: 'Meet';
	src:url("Roboto-Regular.ttf");
	font-weight: 500;
}
@font-face {
	font-family: 'Meet';
	src:url("Roboto-Medium.ttf");
	font-weight: 700;
}

@font-face {
	font-family: 'Meet';
	src:url("Roboto-Bold.ttf");
	font-weight: 900;
}

@font-face {
	font-family: 'serif__';
	font-display: auto; /* or block, swap, fallback, optional */
	src:url("Lora-Medium.ttf");
	font-weight: 400;
}

@media screen, print {html body{font-family: 'Meet', arial, helvetica, "Droid Sans", sans-serif;}}


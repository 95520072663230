.root{
  height: 100%;
  background: var(--white);
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top:var(--margin-1);
}

:global(.App.mobile) .root{
  padding-top:0;
}

.search{
  padding: var(--margin-05);
}


.section{
  /*maxHeight="calc(50% - 46px)" minHeight="150px"*/
  border-bottom: 1px solid var(--bg-dark);
  padding-bottom: var(--margin-1);
}
.sectionTitle{
  color:var(--bg-dark);
  font-size:var(--fsize_4);
  padding:var(--margin-1) var(--margin-1) 0 var(--margin-1);
  display: flex;
  justify-content: space-between;
  font-weight: 100;
}

:global(.mobile) .sectionTitle{
  font-size:var(--fsize_7);
}


.sectionTitle>button{
  z-index: 3;
}


:global(.App:not(.mobile)) .sectionTitle>button{
  padding:var(--margin-1);
  margin-top: -10px;
}


.groupUserList{
  padding-left:var(--margin-2);
  /*margin-top:calc(-1 * var(--margin-1));*/
}

.workspaceRow{
  padding-bottom:var(--margin-1) ;
}

.editGroupIcon{
  color:var(--black);
  margin-right: var(--margin-1);
}

.groupCollapse{
  color:var(--main);
  position: absolute;
  left:0;
  transform: translateY(-0%) rotate(180deg);
}

.groupCollapse.open{
  transform: translateY(-0%)  rotate(90deg);
}